export const wordsRepository = {
    1: {
        name: "ĀBOLS",
        hint: "Apaļš auglis, kas aug kokā. Var būt sarkans, zaļš vai dzeltens. No tā var pagatavot sulu.",
        image: "/assets/abols.svg"
    },
    2: {
        name: "ACS",
        hint: "Orgāns, ar ko mēs redzam. Tās ir divas un tās var būt dažādās krāsās.",
        image: "/assets/acs.svg"
    },
    3: {
        name: "AITA",
        hint: "Mājas dzīvnieks ar vilnainu kažoku. No tās iegūst vilnu.",
        image: "/assets/aita.svg"
    },
    4: {
        name: "AKVĀRIJS",
        hint: "Stikla trauks, kurā dzīvo zivis.",
        image: "/assets/akvarijs.svg"
    },
    5: {
        name: "ARBŪZS",
        hint: "Liels, apaļš auglis ar zaļu mizu un sarkanu vidu. Iekšā ir melnas sēklas.",
        image: "/assets/arbuzs.svg"
    },
    6: {
        name: "ĀRSTS",
        hint: "Cilvēks, kurš ārstē slimos un palīdz atveseļoties.",
        image: "/assets/arsts.svg"
    },
    7: {
        name: "AUSKARI",
        hint: "Rotaslietas, kuras valkā ausu ļipiņās.",
        image: "/assets/auskari.svg"
    },
    8: {
        name: "AUSS",
        hint: "Orgāns, ar ko mēs dzirdam skaņas.",
        image: "/assets/auss.svg"
    },
    9: {
        name: "AUTOBUSS",
        hint: "Liels transportlīdzeklis, kas pārvadā daudz pasažieru.",
        image: "/assets/autobuss.svg"
    },
    10: {
        name: "AVENES",
        hint: "Sarkanas ogas, kuras aug krūmā. Oga kā cepurīte no daudziem maziem burbulīšiem.",
        image: "/assets/avenes.svg"
    },
    11: {
        name: "BANĀNS",
        hint: "Dzeltens auglis ar mīkstu vidu un biezu mizu.",
        image: "/assets/banans.svg"
    },
    12: {
        name: "BEBRS",
        hint: "Dzīvnieks, kas dzīvo pie ūdens un būvē dambjus.",
        image: "/assets/bebrs.svg"
    },
    13: {
        name: "BĒRZS",
        hint: "Koks ar baltu mizu un zaļām lapām.",
        image: "/assets/berzs.svg"
    },
    14: {
        name: "BIETE",
        hint: "Sarkans dārzenis, kas aug zemē.",
        image: "/assets/biete.svg"
    },
    15: {
        name: "BIKSES",
        hint: "Apģērba gabals, ko valkā uz kājām.",
        image: "/assets/bikses.svg"
    },
    16: {
        name: "BITE",
        hint: "Kukainis, kas ražo medu un kura dzēliens var būt sāpīgs.",
        image: "/assets/bite.svg"
    },
    17: {
        name: "BĻODA",
        hint: "Trauks, no kura ēd zupu vai putru.",
        image: "/assets/bloda.svg"
    },
    18: {
        name: "BLŪZE",
        hint: "Sieviešu apģērba gabals, ko valkā augšdaļā.",
        image: "/assets/bluze.svg"
    },
    19: {
        name: "BUMBA",
        hint: "Apaļš priekšmets, ar ko spēlējas bērni.",
        image: "/assets/bumba.svg"
    },
    20: {
        name: "CĀLIS",
        hint: "Vistas mazulis, dzeltens putniņš.",
        image: "/assets/calis.svg"
    },
    21: {
        name: "CELMS",
        hint: "Tas, kas paliek pāri no koka pēc nozāģēšanas.",
        image: "/assets/celms.svg"
    },
    22: {
        name: "CEPURE",
        hint: "To valkā uz galvas, lai pasargātu no saules vai aukstuma.",
        image: "/assets/cepure.svg"
    },
    23: {
        name: "ČIEKURS",
        hint: "Egles vai priedes auglis, kas nokrīt no koka.",
        image: "/assets/ciekurs.svg"
    },
    24: {
        name: "CIMDI",
        hint: "Tos valkā uz rokām, lai nesaltu.",
        image: "/assets/cimdi.svg"
    },
    25: {
        name: "CŪKA",
        hint: "Mājas dzīvnieks, no kura iegūst gaļu. Rukšķ.",
        image: "/assets/cuka.svg"
    },
    26: {
        name: "ČŪSKA",
        hint: "Garš rāpulis bez kājām, kas šņāc.",
        image: "/assets/cuska.svg"
    },
    27: {
        name: "DAKŠIŅA",
        hint: "Galda piederums ar zariem ēdiena ēšanai.",
        image: "/assets/daksina.svg"
    },
    28: {
        name: "DATORS",
        hint: "Elektroniska ierīce, ar kuru var strādāt un spēlēt spēles.",
        image: "/assets/dators.svg"
    },
    29: {
        name: "DĀVANA",
        hint: "To dāvina dzimšanas dienā vai svētkos.",
        image: "/assets/davana.svg"
    },
    30: {
        name: "DEGUNS",
        hint: "Ar to var saost smaržas un smakas.",
        image: "/assets/deguns.svg"
    },
    31: {
        name: "DĪVĀNS",
        hint: "Mēbele, uz kuras var sēdēt vai gulēt.",
        image: "/assets/divans.svg"
    },
    32: {
        name: "DURVIS",
        hint: "Caur tām var ieiet un iziet no telpas.",
        image: "/assets/durvis.svg"
    },
    33: {
        name: "DUŠA",
        hint: "Tur var nomazgāties zem tekoša ūdens.",
        image: "/assets/dusa.svg"
    },
    34: {
        name: "DVIELIS",
        hint: "Ar to nosusina rokas un ķermeni pēc mazgāšanās.",
        image: "/assets/dvielis.svg"
    },
    35: {
        name: "DZENIS",
        hint: "Putns, kas kaļ kokus un meklā tajos kukaiņus.",
        image: "/assets/dzenis.svg"
    },
    36: {
        name: "DZĒRVE",
        hint: "Gārnis putns ar garu kaklu, kas rudenī lido uz siltajām zemēm.",
        image: "/assets/dzerve.svg"
    },
    37: {
        name: "EGLE",
        hint: "Mūžzaļš skuju koks, ko rotā Ziemassvētkos.",
        image: "/assets/egle.svg"
    },
    38: {
        name: "ENKURS",
        hint: "To nolaižot ūdenī, kuģis paliek uz vietas.",
        image: "/assets/enkurs.svg"
    },
    39: {
        name: "EZERS",
        hint: "Liela ūdenstilpne ar stāvošu ūdeni.",
        image: "/assets/ezers.svg"
    },
    40: {
        name: "EZIS",
        hint: "Mazs dzīvnieks ar adatām uz muguras.",
        image: "/assets/ezis.svg"
    },
    41: {
        name: "GAILIS",
        hint: "Vistas tēviņš, kas no rīta modina ar savu dziedāšanu.",
        image: "/assets/gailis.svg"
    },
    42: {
        name: "GALDS",
        hint: "Mēbele, pie kuras ēd vai strādā.",
        image: "/assets/galds.svg"
    },
    43: {
        name: "ĢĒRBONIS",
        hint: "Valsts, pilsētas vai dzimtas simbols.",
        image: "/assets/gerbonis.svg"
    },
    44: {
        name: "GLEZNA",
        hint: "Mākslinieka radīts attēls uz audekla.",
        image: "/assets/glezna.svg"
    },
    45: {
        name: "GLOBUSS",
        hint: "Apaļš Zemes modelis.",
        image: "/assets/globuss.svg"
    },
    46: {
        name: "GOVS",
        hint: "Mājas dzīvnieks, kas dod pienu.",
        image: "/assets/govs.svg"
    },
    47: {
        name: "GRĀMATA",
        hint: "Tajā var lasīt stāstus un pasakas.",
        image: "/assets/gramata.svg"
    },
    48: {
        name: "GREDZENS",
        hint: "Rotaslieta, ko valkā uz pirksta.",
        image: "/assets/gredzens.svg"
    },
    49: {
        name: "GULBIS",
        hint: "Liels, balts ūdensputns ar garu kaklu.",
        image: "/assets/gulbis.svg"
    },
    50: {
        name: "GULTA",
        hint: "Mēbele, kurā guļ.",
        image: "/assets/gulta.svg"
    },
    51: {
        name: "GURĶIS",
        hint: "Zaļš dārzenis, ko bieži liek salātos.",
        image: "/assets/gurkis.svg"
    },
    52: {
        name: "JAHTA",
        hint: "Izpriecu kuģis ar buru.",
        image: "/assets/jahta.svg"
    },
    53: {
        name: "JAKA",
        hint: "Silts apģērba gabals, ko velk virsū.",
        image: "/assets/jaka.svg"
    },
    54: {
        name: "JĀŅOGAS",
        hint: "Mazas, sarkanas ogas, kas aug ķekaros.",
        image: "/assets/janogas.svg"
    },
    55: {
        name: "JŪRA",
        hint: "Liela ūdenstilpne ar sāļu ūdeni.",
        image: "/assets/jura.svg"
    },
    56: {
        name: "KABACIS",
        hint: "Tas dārzenis, ko visi radinieki mēģina viens otram iesmērēt rudenī, bet nevienam to nevajag.",
        image: "/assets/kabacis.svg"
    },
    57: {
        name: "KAIJA",
        hint: "Balts putns, kas dzīvo pie jūras.",
        image: "/assets/kaija.svg"
    },
    58: {
        name: "KAĶIS",
        hint: "Mājas dzīvnieks, kas ņaud un ķer peles.",
        image: "/assets/kakis.svg"
    },
    59: {
        name: "KAKLS",
        hint: "Ķermeņa daļa starp galvu un pleciem.",
        image: "/assets/kakls.svg"
    },
    60: {
        name: "KALNI",
        hint: "Augstas zemes virsmas formas.",
        image: "/assets/kalni.svg"
    },
    61: {
        name: "KAMENE",
        hint: "Liels, pūkains kukainītis, kas dūc.",
        image: "/assets/kamene.svg"
    },
    62: {
        name: "KAMIELIS",
        hint: "Tuksneša dzīvnieks ar kupri.",
        image: "/assets/kamielis.svg"
    },
    63: {
        name: "KAMOLS",
        hint: "Satīts dzijas vai diegu ritulītis.",
        image: "/assets/kamols.svg"
    },
    64: {
        name: "KĀPNES",
        hint: "Pa tām var uzkāpt augšā vai nokāpt lejā.",
        image: "/assets/kapnes.svg"
    },
    65: {
        name: "KĀPOSTS",
        hint: "Liels, apaļš dārzenis ar zaļām lapām.",
        image: "/assets/kaposts.svg"
    },
    66: {
        name: "KAROGS",
        hint: "Valsts simbols uz kāta.",
        image: "/assets/karogs.svg"
    },
    67: {
        name: "KAROTE",
        hint: "Galda piederums šķidra ēdiena ēšanai.",
        image: "/assets/karote.svg"
    },
    68: {
        name: "KARTE",
        hint: "Zemes virsmas attēlojums uz papīra.",
        image: "/assets/karte.svg"
    },
    69: {
        name: "KASTANIS",
        hint: "Koka brūnais auglis ar dzeloņainu čaulu.",
        image: "/assets/kastanis.svg"
    },
    70: {
        name: "KAZA",
        hint: "Mājas dzīvnieks ar ragiem, kas dod pienu.",
        image: "/assets/kaza.svg"
    },
    71: {
        name: "ĶEMME",
        hint: "Ar to sakārto matus.",
        image: "/assets/kemme.svg"
    },
    72: {
        name: "ĶIPLOKS",
        hint: "Ass dārzenis, ko lieto ēdienu gatavošanā.",
        image: "/assets/kiploks.svg"
    },
    73: {
        name: "ĶIRBIS",
        hint: "Liels, oranžs dārzenis, ko izmanto Helovīnos.",
        image: "/assets/kirbis.svg"
    },
    74: {
        name: "KLEITA",
        hint: "Sieviešu apģērbs, kas sniedzas zem ceļiem.",
        image: "/assets/kleita.svg"
    },
    75: {
        name: "KRĀNS",
        hint: "No tā tek ūdens izlietnē vai vannā.",
        image: "/assets/krans.svg"
    },
    76: {
        name: "KRĀSAS",
        hint: "Ar tām var zīmēt un krāsot.",
        image: "/assets/krasas.svg"
    },
    77: {
        name: "KREKLS",
        hint: "Apģērba gabals, ko valkā augšdaļā.",
        image: "/assets/krekls.svg"
    },
    78: {
        name: "KRĒSLS",
        hint: "Mēbele, uz kuras sēž.",
        image: "/assets/kresls.svg"
    },
    79: {
        name: "KRŪZE",
        hint: "Trauks, no kura dzer tēju vai kafiju.",
        image: "/assets/kruze.svg"
    },
    80: {
        name: "KUĢIS",
        hint: "Liels peldlīdzeklis, kas brauc pa ūdeni.",
        image: "/assets/kugis.svg"
    },
    81: {
        name: "KUMODE",
        hint: "Mēbele ar atvilktnēm drēbju glabāšanai.",
        image: "/assets/kumode.svg"
    },
    82: {
        name: "KURMIS",
        hint: "Dzīvnieks, kas rok alas zemē.",
        image: "/assets/kurmis.svg"
    },
    83: {
        name: "KURPES",
        hint: "Apavi, ko valkā uz kājām.",
        image: "/assets/kurpes.svg"
    },
    84: {
        name: "LĀCIS",
        hint: "Liels, brūns meža dzīvnieks.",
        image: "/assets/lacis.svg"
    },
    85: {
        name: "LAIVA",
        hint: "Mazs peldlīdzeklis.",
        image: "/assets/laiva.svg"
    },
    86: {
        name: "LAKATS",
        hint: "Auduma gabals, ko sievietes sien ap galvu vai pleciem.",
        image: "/assets/lakats.svg"
    },
    87: {
        name: "LAMPA",
        hint: "Tā dod gaismu, kad ir tumšs.",
        image: "/assets/lampa.svg"
    },
    88: {
        name: "LAPA",
        hint: "Zaļa koka daļa, kas rudenī nokrīt.",
        image: "/assets/lapa.svg"
    },
    89: {
        name: "LAPSA",
        hint: "Sarkans, viltīgs meža dzīvnieks.",
        image: "/assets/lapsa.svg"
    },
    90: {
        name: "LAUVA",
        hint: "Liels, plēsīgs kaķu dzimtas dzīvnieks.",
        image: "/assets/lauva.svg"
    },
    91: {
        name: "LAZDA",
        hint: "Koks, uz kura aug rieksti.",
        image: "/assets/lazda.svg"
    },
    92: {
        name: "LELLE",
        hint: "Rotaļlieta, ar ko spēlējas meitenes.",
        image: "/assets/lelle.svg"
    },
    93: {
        name: "LIEPA",
        hint: "Koks ar smaržīgiem ziediem.",
        image: "/assets/liepa.svg"
    },
    94: {
        name: "LINEĀLS",
        hint: "Ar to mēra un velk taisnas līnijas.",
        image: "/assets/lineals.svg"
    },
    95: {
        name: "LOGS",
        hint: "Caur to var redzēt, kas notiek ārā.",
        image: "/assets/logs.svg"
    },
    96: {
        name: "LŪPAS",
        hint: "Ar tām var runāt un smaidīt.",
        image: "/assets/lupas.svg"
    },
    97: {
        name: "LUSTRA",
        hint: "Pie griestiem piestiprināta lampa.",
        image: "/assets/lustra.svg"
    },
    98: {
        name: "LĀPA",
        hint: "Degošs priekšmets, kas dod gaismu.",
        image: "/assets/lapa.svg"
    },
    99: {
        name: "MAGONE",
        hint: "Sarkana puķe ar melniem putekšņiem.",
        image: "/assets/magone.svg"
    },
    100: {
        name: "MAIZE",
        hint: "To cep no miltiem un ēd katru dienu.",
        image: "/assets/maize.svg"
    },
    101: {
        name: "MĀJA",
        hint: "Ēka, kurā dzīvo cilvēki.",
        image: "/assets/maja.svg"
    },
    102: {
        name: "MĀKOŅI",
        hint: "Sarkana puķe ar melniem putekšņiem.",
        image: "/assets/makoni.svg"
    },
    103: {
        name: "MAKŠĶERE",
        hint: "Ar to ķer zivis.",
        image: "/assets/makskere.svg"
    },
    104: {
        name: "MAMMA",
        hint: "Sieviete, kas ir dzemdējusi bērnu.",
        image: "/assets/mamma.svg"
    },
    105: {
        name: "MĀRĪTE",
        hint: "Sarkans kukainītis ar melniem punktiņiem.",
        image: "/assets/marite.svg"
    },
    106: {
        name: "MAŠĪNA",
        hint: "Transportlīdzeklis ar četriem riteņiem.",
        image: "/assets/masina.svg"
    },
    107: {
        name: "MATI",
        hint: "Tie aug uz galvas.",
        image: "/assets/mati.svg"
    },
    108: {
        name: "MELLENES",
        hint: "Mazas, zilas ogas, kas aug mežā.",
        image: "/assets/mellenes.svg"
    },
    109: {
        name: "MĒNESS",
        hint: "Spīd debesīs naktī.",
        image: "/assets/meness.svg"
    },
    110: {
        name: "MĒTELIS",
        hint: "Garš, silts virsdrēbes gabals.",
        image: "/assets/metelis.svg"
    },
    111: {
        name: "MUŠA",
        hint: "Lidojošs kukains, kas dūc.",
        image: "/assets/musa.svg"
    },
    112: {
        name: "MUTE",
        hint: "Ar to var runāt un ēst.",
        image: "/assets/mute.svg"
    },
    113: {
        name: "NARCISE",
        hint: "Dzeltena pavasara puķe.",
        image: "/assets/narcise.svg"
    },
    114: {
        name: "NAZIS",
        hint: "Ass priekšmets griešanai.",
        image: "/assets/nazis.svg"
    },
    115: {
        name: "NĪLZIRGS",
        hint: "Liels dzīvnieks, kas dzīvo Āfrikā pie upes.",
        image: "/assets/nilzirgs.svg"
    },
    116: {
        name: "NŪJA",
        hint: "Garš koka vai metāla stienis.",
        image: "/assets/nuja.svg"
    },
    117: {
        name: "ODS",
        hint: "Mazs kukains, kas dzeļ un sūc asinis.",
        image: "/assets/ods.svg"
    },
    118: {
        name: "OLA",
        hint: "No tās izšķiļas cālis. To dēj vista.",
        image: "/assets/ola.svg"
    },
    119: {
        name: "OZOLS",
        hint: "Liels, spēcīgs koks, uz kura aug zīles.",
        image: "/assets/ozols.svg"
    },
    120: {
        name: "OZOLZĪLE",
        hint: "Ozola auglis.",
        image: "/assets/ozolzile.svg"
    },
    121: {
        name: "PAGALE",
        hint: "Malkas gabals.",
        image: "/assets/pagale.svg"
    },
    122: {
        name: "PANNA",
        hint: "Trauks ēdiena cepšanai.",
        image: "/assets/panna.svg"
    },
    123: {
        name: "PAPARDE",
        hint: "Zaļš augs ar lielām, skaistām lapām.",
        image: "/assets/paparde.svg"
    },
    124: {
        name: "PAPRIKA",
        hint: "Dārzenis, kas var būt sarkans, dzeltens vai zaļš.",
        image: "/assets/paprika.svg"
    },
    125: {
        name: "PĀVS",
        hint: "Skaists putns ar krāsainu asti.",
        image: "/assets/pavs.svg"
    },
    126: {
        name: "PĒDA",
        hint: "Ķermeņa daļa, ar ko staigā.",
        image: "/assets/peda.svg"
    },
    127: {
        name: "PELE",
        hint: "Mazs grauzējs ar garu asti.",
        image: "/assets/pele.svg"
    },
    128: {
        name: "PĒRTIĶIS",
        hint: "Dzīvnieks, kas līdzīgs cilvēkam un dzīvo džungļos.",
        image: "/assets/pertikis.svg"
    },
    129: {
        name: "PIENENE",
        hint: "Dzeltena pļavas puķe.",
        image: "/assets/pienene.svg"
    },
    130: {
        name: "PĪLE",
        hint: "Ūdensputns, kas peld un pēkšķ.",
        image: "/assets/pile.svg"
    },
    131: {
        name: "PLĪTS",
        hint: "Uz tās gatavo ēdienu.",
        image: "/assets/plits.svg"
    },
    132: {
        name: "PLŪME",
        hint: "Violets auglis, kas aug kokā.",
        image: "/assets/plume.svg"
    },
    133: {
        name: "PRIEDE",
        hint: "Mūžzaļš skuju koks.",
        image: "/assets/priede.svg"
    },
    134: {
        name: "PŪCE",
        hint: "Nakts putns ar lielām acīm.",
        image: "/assets/puce.svg"
    },
    135: {
        name: "PŪĶIS",
        hint: "Latvijas pasaku tēls, pūķis.",
        image: "/assets/pukis.svg"
    },
    136: {
        name: "PUĶUPODS",
        hint: "Trauks, kurā aug puķes.",
        image: "/assets/pukupods.svg"
    },
    137: {
        name: "PUPAS",
        hint: "Dārzenis, kas aug garā pākstī.",
        image: "/assets/pupas.svg"
    },
    138: {
        name: "RADIO",
        hint: "Ierīce mūzikas un ziņu klausīšanai.",
        image: "/assets/radio.svg"
    },
    139: {
        name: "RAGAVAS",
        hint: "Ar tām var braukt pa sniegu no kalna.",
        image: "/assets/ragavas.svg"
    },
    140: {
        name: "RAĶETE",
        hint: "Ar to var lidot kosmosā.",
        image: "/assets/rakete.svg"
    },
    141: {
        name: "RIEKSTI",
        hint: "Cieti augļi cietā čaulā.",
        image: "/assets/rieksti.svg"
    },
    142: {
        name: "RĪVE",
        hint: "Virtuves rīks produktu rīvēšanai.",
        image: "/assets/rive.svg"
    },
    143: {
        name: "RONIS",
        hint: "Dzīvnieks, kas dzīvo jūrā un labi peld.",
        image: "/assets/ronis.svg"
    },
    144: {
        name: "RŪĶIS",
        hint: "No tiem maļ miltus tummaizei.",
        image: "/assets/rukis.svg"
    },
    145: {
        name: "SALA",
        hint: "Zeme, ko no visām pusēm ieskauj ūdens.",
        image: "/assets/sala.svg"
    },
    146: {
        name: "ŠALLE",
        hint: "To tin ap kaklu, lai nebūtu auksti.",
        image: "/assets/salle.svg"
    },
    147: {
        name: "SAULE",
        hint: "Spīd debesīs dienā un dod siltumu.",
        image: "/assets/saule.svg"
    },
    148: {
        name: "SĒNES",
        hint: "Aug mežā pēc lietus.",
        image: "/assets/senes.svg"
    },
    149: {
        name: "SIERS",
        hint: "Piena produkts, ko gatavo no piena.",
        image: "/assets/siers.svg"
    },
    150: {
        name: "SĪPOLS",
        hint: "Dārzenis, no kura raud acis.",
        image: "/assets/sipols.svg"
    },
    151: {
        name: "SKAPIS",
        hint: "Mēbele, kurā glabā drēbes.",
        image: "/assets/skapis.svg"
    },
    152: {
        name: "ŠĶĒRES",
        hint: "Ar tām var griezt papīru vai audumu.",
        image: "/assets/skeres.svg"
    },
    153: {
        name: "ŠĶĪVIS",
        hint: "Trauks, no kura ēd.",
        image: "/assets/skivis.svg"
    },
    154: {
        name: "SLĒPES",
        hint: "Ar tām brauc pa sniegu.",
        image: "/assets/slepes.svg"
    },
    155: {
        name: "SLIEKA",
        hint: "Garš, tievs tārps, kas dzīvo zemē.",
        image: "/assets/slieka.svg"
    },
    156: {
        name: "SLOTA",
        hint: "Ar to slauka grīdu.",
        image: "/assets/slota.svg"
    },
    157: {
        name: "SMILGA",
        hint: "Gara, tieva zāle.",
        image: "/assets/smilga.svg"
    },
    158: {
        name: "ŠORTI",
        hint: "Īsas bikses siltam laikam.",
        image: "/assets/sorti.svg"
    },
    159: {
        name: "SPAINIS",
        hint: "Trauks ūdens nešanai.",
        image: "/assets/spainis.svg"
    },
    160: {
        name: "SPILVENS",
        hint: "Mīksts priekšmets, uz kura guļ galva.",
        image: "/assets/spilvens.svg"
    },
    161: {
        name: "SPOGULIS",
        hint: "Tajā var redzēt savu attēlu.",
        image: "/assets/spogulis.svg"
    },
    162: {
        name: "SUNS",
        hint: "Mājas dzīvnieks, kas rej un sargā māju.",
        image: "/assets/suns.svg"
    },
    163: {
        name: "SVĀRKI",
        hint: "Sieviešu apģērba gabals no vidukļa uz leju.",
        image: "/assets/svarki.svg"
    },
    164: {
        name: "SVECES",
        hint: "Tās deg un dod gaismu.",
        image: "/assets/sveces.svg"
    },
    165: {
        name: "SVILPE",
        hint: "Ar to var svilpt un radīt skaņu.",
        image: "/assets/svilpe.svg"
    },
    166: {
        name: "TĀFELE",
        hint: "Uz tās raksta ar krītu.",
        image: "/assets/tafele.svg"
    },
    167: {
        name: "TĀLRUNIS",
        hint: "Ar to var piezvanīt un sarunāties.",
        image: "/assets/talrunis.svg"
    },
    168: {
        name: "TAURENIS",
        hint: "Krāsains kukainītis ar lieliem spārniem.",
        image: "/assets/taurenis.svg"
    },
    169: {
        name: "TĒJKANNA",
        hint: "Tajā vāra tēju.",
        image: "/assets/tejkanna.svg"
    },
    170: {
        name: "TĪĢERIS",
        hint: "Liels, svītrains kaķu dzimtas plēsējs.",
        image: "/assets/tigeris.svg"
    },
    171: {
        name: "TILTS",
        hint: "Pa to var pāriet pāri upei.",
        image: "/assets/tilts.svg"
    },
    172: {
        name: "TOMĀTS",
        hint: "Sarkans dārzenis, ko liek salātos.",
        image: "/assets/tomats.svg"
    },
    173: {
        name: "TORTE",
        hint: "Saldais ēdiens svētkiem.",
        image: "/assets/torte.svg"
    },
    174: {
        name: "TRAKTORS",
        hint: "Mašīna lauku darbiem.",
        image: "/assets/traktors.svg"
    },
    175: {
        name: "TRAMVAJS",
        hint: "Brauc pa sliedēm pilsētā.",
        image: "/assets/tramvajs.svg"
    },
    176: {
        name: "UGUNS",
        hint: "Deg un dod siltumu.",
        image: "/assets/uguns.svg"
    },
    177: {
        name: "UPE",
        hint: "Pa to tek ūdens.",
        image: "/assets/upe.svg"
    },
    178: {
        name: "UPENES",
        hint: "Melnas ogas, kas aug dārzā.",
        image: "/assets/upenes.svg"
    },
    179: {
        name: "VANNA",
        hint: "Tajā mazgājas.",
        image: "/assets/vanna.svg"
    },
    180: {
        name: "VARDE",
        hint: "Zaļš dzīvnieks, kas lec un kurkst.",
        image: "/assets/varde.svg"
    },
    181: {
        name: "VĀRTI",
        hint: "Pa tiem iebrauc pagalmā.",
        image: "/assets/varti.svg"
    },
    182: {
        name: "VĀVERE",
        hint: "Dzīvnieks ar pušķainu asti, kas dzīvo kokos.",
        image: "/assets/vavere.svg"
    },
    183: {
        name: "VESTE",
        hint: "Apģērbs bez rokām, ko velk virs krekla.",
        image: "/assets/veste.svg"
    },
    184: {
        name: "VILKS",
        hint: "Pelēks meža dzīvnieks, kas gaudo.",
        image: "/assets/vilks.svg"
    },
    185: {
        name: "VĪNOGAS",
        hint: "Sulīgi augļi, kas aug ķekaros.",
        image: "/assets/vinogas.svg"
    },
    186: {
        name: "VISTA",
        hint: "Mājputns, kas dēj olas.",
        image: "/assets/vista.svg"
    },
    187: {
        name: "ZĀBAKI",
        hint: "Gari apavi lietainiem laikiem.",
        image: "/assets/zabaki.svg"
    },
    188: {
        name: "ZĀLE",
        hint: "Zaļi augi, kas aug pļavā.",
        image: "/assets/zale.svg"
    },
    189: {
        name: "ZEBRA",
        hint: "Svītrains dzīvnieks melnā un baltā krāsā.",
        image: "/assets/zebra.svg"
    },
    190: {
        name: "ZEĶES",
        hint: "Tās velk kājās.",
        image: "/assets/zekes.svg"
    },
    191: {
        name: "ZEMENES",
        hint: "Sarkanas, saldas ogas.",
        image: "/assets/zemenes.svg"
    },
    192: {
        name: "ZIBENS",
        hint: "Spilgta gaisma negaisa laikā.",
        image: "/assets/zibens.svg"
    },
    193: {
        name: "ZIEPES",
        hint: "Ar tām mazgājas.",
        image: "/assets/ziepes.svg"
    },
    194: {
        name: "ZILONIS",
        hint: "Liels dzīvnieks ar garu snuķi.",
        image: "/assets/zilonis.svg"
    },
    195: {
        name: "ZĪMULIS",
        hint: "Ar to raksta un zīmē.",
        image: "/assets/zimulis.svg"
    },
    196: {
        name: "ŽIRAFE",
        hint: "Dzīvnieks ar ļoti garu kaklu.",
        image: "/assets/zirafe.svg"
    },
    197: {
        name: "ZIRGS",
        hint: "Mājas dzīvnieks, uz kura var jāt.",
        image: "/assets/zirgs.svg"
    },
    198: {
        name: "ZIRŅI",
        hint: "Apaļi, zaļi dārzeņi pākstī.",
        image: "/assets/zirni.svg"
    },
    199: {
        name: "ZIVS",
        hint: "Dzīvnieks, kas dzīvo ūdenī un peld.",
        image: "/assets/zivs.svg"
    },
    200: {
        name: "ŽOGS",
        hint: "Koka vai metāla stienis durvju aizvēršanai.",
        image: "/assets/zogs.svg"
    },
    201: {
        name: "ZOSS",
        hint: "Liels, balts mājputns ar garu kaklu.",
        image: "/assets/zoss.svg"
    },
    202: {
        name: "ZVAIGZNE",
        hint: "Spīd debesīs naktī.",
        image: "/assets/zvaigzne.svg"
    }
};

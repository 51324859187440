import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

export default function LetterBlocks({
                                         word,
                                         level,
                                         onComplete,
                                         focusedIndex,
                                         onFocusChange,
                                         onWordFull
                                     }) {
    const [letters, setLetters] = useState([]);
    const blocksRef = useRef([]);
    const isAndroid = useRef(false);
    const wordRef = useRef(word);

    // Detect Android platform
    useEffect(() => {
        isAndroid.current = /Android/i.test(navigator.userAgent);
    }, []);

    // initialization effect, only run when word changes or first load
    useEffect(() => {
        // Check if we have a word with letters
        if (word?.letters) {
            // Initialize letters
            const initialLetters = word.letters.map((letter, index) => ({
                value: level === 1 && index === 0 ? letter : '',
                isCorrect: level === 1 && index === 0
            }));
            setLetters(initialLetters);
            wordRef.current = word;
        }
    }, [word?.id]); // Only depend on word.id change

    useEffect(() => {
        if (blocksRef.current[focusedIndex]) {
            blocksRef.current[focusedIndex].focus();
        }
    }, [focusedIndex]);

    const handleInput = (value, index) => {
        if (!word || !word.letters) return;

        // Clean the input value
        const cleanedValue = value.replace(/[^A-Za-zĀāČčĒēĢģĪīĶķĻļŅņŠšŪūŽž]/g, '');
        if (!cleanedValue) return;

        // Take only the last character for Android
        const inputChar = isAndroid.current ? cleanedValue.slice(-1) : cleanedValue;

        const newLetters = [...letters];
        newLetters[index] = {
            value: inputChar.toUpperCase(),
            isCorrect: inputChar.toUpperCase() === word.letters[index].toUpperCase()
        };
        setLetters(newLetters);

        // Check if all letters are filled
        if (newLetters.every(l => l.value)) {
            onWordFull(newLetters);
            return;
        }

        // Check if word is complete and correct
        if (newLetters.every(l => l.isCorrect)) {
            onComplete();
            return;
        }

        // Move focus to next empty block
        const nextIndex = letters.findIndex((l, i) => i > index && !l.value);
        if (nextIndex !== -1) {
            onFocusChange(nextIndex);
        }
    };

    const handleBackspace = (index) => {
        // Don't handle backspace for the first letter in level 1
        if (level === 1 && index === 0) return;

        const newLetters = [...letters];

        // If current block is empty and we're not at the first block,
        // clear the previous block and move focus back
        if (!letters[index].value && index > 0) {
            // Don't clear first letter in level 1
            if (!(level === 1 && index - 1 === 0)) {
                newLetters[index - 1] = {
                    value: '',
                    isCorrect: false
                };
                setLetters(newLetters);
                onFocusChange(index - 1);
            }
        } else {
            // Clear current block if it has a value
            newLetters[index] = {
                value: '',
                isCorrect: false
            };
            setLetters(newLetters);
        }
    };

    const handleKeyDown = (e, index) => {
        // Handle backspace key
        if (e.key === 'Backspace') {
            e.preventDefault();
            handleBackspace(index);
            return;
        }
        if (isAndroid.current) {
            // Don't prevent default on Android to allow input
            if (/^[A-Za-zĀāČčĒēĢģĪīĶķĻļŅņŠšŪūŽž]$/.test(e.key)) {
                handleInput(e.key, index);
            }
        } else {
            // Original behavior for other platforms
            e.preventDefault();
            if (/^[A-Za-zĀāČčĒēĢģĪīĶķĻļŅņŠšŪūŽž]$/.test(e.key)) {
                handleInput(e.key, index);
            }
        }
    };

    const getBackgroundStyle = (letter, index) => {
        if (letter.value) {
            if (index === focusedIndex) {
                // Return focused and filled style
                return 'bg-gradient-to-b from-[#14a5b3] to-[#0a7981]';
            }
            // Return regular filled style
            return 'bg-gradient-to-b from-[#0c8894] to-[#065c63]';
        }
        // Return empty style
        return 'bg-white';
    };

    return (
        <div className="flex justify-center space-x-1 md:space-x-3">
            {letters.map((letter, index) => (
                <motion.input
                    key={`${word?.id}-${index}`}
                    ref={el => blocksRef.current[index] = el}
                    type="text"
                    maxLength={1}
                    value={letter.value}
                    className={`
                        w-10 h-10 md:h-[53px] md:w-[53px] xl:h-[71px] xl:w-[71px]
                        text-center 
                        text-[26.18px] md:text-[34.69px] xl:text-[46.47px]
                        leading-[63.67px] 
                        tracking-[-0.93px] 
                        uppercase 
                        rounded-[2.91px] md:rounded 
                        font-bold font-outfit 
                        shadow-[-4px_4px_4px_0px_rgba(0,0,0,0.25)]
                        ${getBackgroundStyle(letter, index)}
                        ${letter.value ? 'text-white' : 'text-black'}
                    `}
                    style={{ textShadow: '2px 4px 2px rgba(0, 0, 0, 0.25)' }}
                    onKeyDown={e => handleKeyDown(e, index)}
                    onChange={e => handleInput(e.target.value, index)}
                    onClick={() => onFocusChange(index)}
                    readOnly={level === 1 && index === 0}
                />
            ))}
        </div>
    );
}
'use client'

import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import GrayButton from "../buttons/GrayButton";
import VioletButton from "../buttons/VioletButton";
import { AboutRepository } from "../../data/AboutRepository";

export default function Modal({ type, onClose, word, restart, onShowAnswer, onTryAgain, player }) {

    const renderContent = () => {
        switch (type) {
            case 'hint':
                return (
                    <div
                        className="w-[343px] md:w-[540px] bg-white rounded-xl shadow inline-flex flex-col md:items-center justify-center gap-5 relative px-6">
                        <div>
                            <button
                                className="absolute top-3 right-4 w-11 h-11 p-2 rounded-lg hover:bg-gray-50 transition duration-400 justify-center items-center inline-flex"
                                onClick={onClose}
                            >
                                <img src="/icons/x-close.svg" alt="Close"/>
                            </button>
                        </div>
                        <div className="w-[72px] h-[72px] bg-[#00d881] rounded-full justify-center items-center gap-[9px] inline-flex">
                            <img className="
                                pl-0.2
                                brightness-0
                                w-[40.50px] h-[40.50px]
                                invert"
                                 src="/icons/help-circle.svg"
                                 alt="Hint"
                            />
                        </div>
                        <div
                            className="text-left text-black text-xl font-normal font-outfit leading-normal p-1 pb-8 md:pb-0">
                            {word.hint}
                        </div>
                        <div className="self-stretch pb-5 justify-center items-start gap-3 inline-flex">
                            <VioletButton onClick={onClose}>
                                Turpināt
                            </VioletButton>
                        </div>
                    </div>
                )
            case 'timeout':
                return (
                    <div
                        className="w-[343px] md:w-[540px] py-6 md:p-6 bg-white rounded-xl shadow inline-flex flex-col items-center justify-center gap-5 relative">
                        <div><img src="/icons/clock.svg" alt=""/></div>
                        <div
                            className="self-stretch text-center text-black text-lg font-semibold font-['Outfit'] leading-7">Diemžēl
                            laiks ir beidzies
                        </div>
                        <div className="flex flex-col md:flex-row self-stretch px-3 md;px-6 justify-center items-start gap-3">
                            <GrayButton onClick={() => {
                                onShowAnswer();
                                onClose();
                            }}>
                                Pareizā atbilde
                            </GrayButton>
                            {word.retries > 0 && (
                                <VioletButton onClick={() => {
                                    onTryAgain();
                                    onClose();
                                }}>
                                    Mēģini vēlreiz
                                </VioletButton>
                            )}
                        </div>
                    </div>
                )
            case 'about':
                return (
                    <div
                        className="m-3 h-[600px] md:h-[700px] md:m-0 md:w-[800px] bg-white rounded-xl shadow inline-flex flex-col gap-5 relative px-4">
                        <div>
                            <button
                                className="absolute top-3 right-4 w-11 h-11 p-2 rounded-lg hover:bg-gray-50 transition duration-400 justify-center items-center inline-flex"
                                onClick={onClose}
                            >
                                <img src="/icons/x-close.svg" alt="Close"/>
                            </button>
                        </div>
                        <div className="pl-1">
                            <div
                                className="w-[72px] h-[72px] bg-[#00d881] rounded-[45px] justify-center items-center gap-[9px] inline-flex">
                                <img className="pl-0.2 brightness-0 invert w-[40.50px] h-[40.50px]" src="/icons/info-circle.svg" alt="Info"/>
                            </div>
                        </div>
                        {/*Text Container*/}
                        <div
                            className="
                                h-[600px]
                                overflow-y-auto
                                [-ms-overflow-style:none]
                                [scrollbar-width:none]
                                [&::-webkit-scrollbar]:hidden
                                px-4
                                text-black
                                text-xl
                                font-normal
                                font-['Outfit']
                                leading-normal"
                        >
                            <div className="flex-col justify-start items-start gap-4 flex">
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <div
                                        className="self-stretch text-black text-lg font-semibold font-outfit leading-7">
                                        { AboutRepository.main.heading }
                                    </div>
                                    <div
                                        className="self-stretch text-black text-sm font-normal font-outfit leading-tight">
                                        { AboutRepository.main.text }
                                    </div>
                                </div>
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <div
                                        className="self-stretch text-black text-lg font-semibold font-outfit leading-7">
                                        { AboutRepository.privacy.heading }
                                    </div>
                                    <div
                                        className="self-stretch text-black text-sm font-normal font-outfit leading-tight">
                                        { AboutRepository.privacy.text }
                                    </div>
                                </div>
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <div
                                        className="self-stretch text-black text-lg font-semibold font-outfit leading-7">
                                        { AboutRepository.contacts.heading }
                                    </div>
                                    <div className="self-stretch">
                                        <span className="text-black text-sm font-normal font-outfit leading-tight">
                                            { AboutRepository.contacts.text }
                                        </span>
                                        <span
                                            className="text-black text-sm font-normal font-outfit underline leading-tight">
                                            { AboutRepository.contacts.email }
                                        </span>
                                        <span className="text-black text-sm font-normal font-outfit leading-tight">
                                            { AboutRepository.contacts.text2 }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="self-stretch pb-5 md:pr-2 justify-center md:justify-end items-center md:items-end gap-3 inline-flex">
                            <VioletButton onClick={onClose}>
                                Turpināt
                            </VioletButton>
                        </div>
                    </div>
                )
            case 'finish':
                return (
                    <div
                        className="w-[343px] md:w-[800px] bg-white rounded-xl shadow inline-flex flex-col gap-5 relative">
                        <div className="self-stretch grow shrink basis-0 flex-col justify-start items-center flex">
                            <div className="self-stretch h-[132.53px] py-3 flex-col flex">
                                <div className="self-stretch h-[108.53px] flex-col items-center flex">
                                    <img src="/icons/diamond.svg" alt=""/>
                                    <div
                                        className="self-stretch justify-center items-center gap-1 inline-flex font-outfit font-semibold leading-normal text-black">
                                        <div className="grow shrink basis-0 text-right">Esi nopelnījis</div>
                                        <div className="text-center text-[31.46px]">{ player.score }</div>
                                        <div className="grow shrink basis-0">dārgumus!</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[343px] h-[323px] md:w-[800px] md:h-[540px] overflow-hidden relative m-3">
                                <motion.img
                                    className="w-full h-full object-cover"
                                    src="/finish_screen.png"
                                    alt="Dārgumu Bagātnieks"
                                    style={{
                                        transformOrigin: 'bottom',
                                        transformStyle: 'preserve-3d'
                                    }}
                                    animate={{
                                        scale: [1, 1.05, 1.05, 1, 1],
                                        scaleY: [1, 1.05, 1.05, 1, 1]
                                    }}
                                    transition={{
                                        duration: 30,
                                        times: [0, 0.4, 0.5, 0.9, 1],
                                        repeat: Infinity,
                                        ease: "easeInOut"
                                    }}
                                />
                            </div>
                        </div>
                        <div className="self-stretch pb-8 justify-center items-center inline-flex">
                            <VioletButton
                                onClick={() => {
                                    restart();
                                    onClose();
                                }}
                            >
                                Spēlēt vēlreiz
                            </VioletButton>
                        </div>

                    </div>
                )
            default:
                return null
        }
    }

    return (
        <>
            {/* Background overlay */}
            <div
                className="fixed inset-0 bg-black bg-opacity-50 z-[19]"
                aria-hidden="true"
            />

            {/* Modal content with animations */}
            <AnimatePresence mode="wait">
                <div className="fixed inset-0 flex items-center justify-center z-[20]">
                    <motion.div
                        key={type}
                        initial={{ scale: 0.95, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.95, opacity: 0 }}
                        transition={{
                            type: "spring",
                            stiffness: 300,
                            damping: 30
                        }}
                    >
                        {renderContent()}
                    </motion.div>
                </div>
            </AnimatePresence>
        </>
    )
}


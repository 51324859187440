'use client'

import React, { useState, useEffect } from 'react'
import { useGame } from '../context/GameContext'
import { CONSTANTS } from '../constants/game-constants'
import Index from './start_screen'
import Timer from './Timer'
import ScoreDisplay from './ScoreDisplay'
import LetterBlocks from './LetterBlocks'
import TileGrid from './TileGrid'
import Gallery from './Gallery'
import Modal from './modals/Modal'
import WordCheckModal from "./modals/WordCheckModal";
import { motion } from 'framer-motion';
import LevelChangeButton from "./buttons/LevelChangeButton";
import AboutButton from "./buttons/AboutButton";

// Helper function to generate penalties array
const generatePenalties = (level) => {
    if (!level) return []

    const size = CONSTANTS.BOARD_SIZE[`LEVEL_${level}`]
    const totalTiles = size * size
    const zeroCount = level === 1 ? 1 : 2
    const penaltyArray = Array(totalTiles).fill(CONSTANTS.TILE_PENALTY)
    const indices = Array.from({length: totalTiles}, (_, i) => i)

    for (let i = 0; i < zeroCount; i++) {
        const randomIndex = Math.floor(Math.random() * indices.length)
        penaltyArray[indices[randomIndex]] = 0
        indices.splice(randomIndex, 1)
    }

    return penaltyArray
}

export default function GameBoard() {
    const { state, dispatch } = useGame();
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [focusedLetterIndex, setFocusedLetterIndex] = useState(0);
    const [revealedTiles, setRevealedTiles] = useState([]);
    const [penalties, setPenalties] = useState(() => generatePenalties(state.player.level));
    // Add a state variable to track level changes
    const [usedShowAnswer, setUsedShowAnswer] = useState(false);
    const [isLevelChange, setIsLevelChange] = useState(false);
    const [showWordCheckModal, setShowWordCheckModal] = useState(false);
    const [wordCheckStage, setWordCheckStage] = useState('initial');
    const [currentLetters, setCurrentLetters] = useState([]);
    const [showAnswerBlocks, setShowAnswerBlocks] = useState(false);
    const [isCompleting, setIsCompleting] = useState(false);
    const isAnyModalOpen = showModal || showWordCheckModal;
    const [isVisible, setIsVisible] = useState(false); //handle board visibility

    useEffect(() => {
        if (state.currentWord) {
            setIsVisible(false)
            const timer = setTimeout(() => {
                setIsVisible(true)
            }, 300) // Increased delay to ensure proper transition
            return () => clearTimeout(timer)
        }
    }, [state.currentWord?.id])

    // Handle key presses
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (!state.player.level || !state.currentWord) return

            switch (e.key) {
                case 'ArrowLeft':
                    e.preventDefault()
                    setFocusedLetterIndex(prev => Math.max(0, prev - 1))
                    break
                case 'Backspace':
                    break
                case ' ':
                    e.preventDefault();
                    setModalType('hint');
                    setShowModal(true);
                    break;
                case 'ArrowRight':
                    e.preventDefault()
                    setFocusedLetterIndex(prev => Math.min((state.currentWord?.letters?.length || 1) - 1, prev + 1))
                    break
            }
        }
        window.addEventListener('keydown', handleKeyPress)
        return () => window.removeEventListener('keydown', handleKeyPress)
    }, [state.player.level, state.currentWord])

    // Update penalties only when a new word starts or level changes
    useEffect(() => {
        if (!state.player.level || !state.currentWord) return
        setPenalties(generatePenalties(state.player.level))
    }, [state.currentWord?.id, state.player.level])

    //Open Game Over modal in case if all word challanges are completed
    useEffect(() => {
        if (state.isGameOver) {
            setModalType('finish');
            setShowModal(true);
        }
    }, [state.isGameOver]);

    // Handle level change
    const handleLevelChange = () => {
        setIsLevelChange(true)
        const newLevel = state.player.level === 1 ? 2 : 1
        setShowWordCheckModal(false);
        setShowAnswerBlocks(false);
        dispatch({
            type: 'INITIALIZE_GAME',
            level: newLevel
        })
        setFocusedLetterIndex(0)
        setIsCompleting(false);
        setRevealedTiles([])
    };
    const handleTimeout = () => {
        setModalType('timeout');
        setShowModal(true);
    };
    const handleTimeoutShowAnswer = () => {
        setShowModal(false);
        handleShowAnswer();
        setShowAnswerBlocks(true);
        setShowWordCheckModal(true);
        setUsedShowAnswer(true);
    };
    const handleTimeoutTryAgain = () => {
        setShowModal(false);
        handleTryAgain();
    };
    const handleTileClick = (penalty) => {
        dispatch({ // deduced player score instantly
            type: 'UPDATE_PLAYER_SCORE',
            points: penalty
        })
        dispatch({ // takes penalty from word score as well to show +- balance in gallery
            type: 'UPDATE_WORD_SCORE',
            points: penalty
        })
    }
    const handleWordFull = (letters) => {
        setTimeout(() => {        // Then set new value
            setCurrentLetters(letters);
        }, 10);
        setWordCheckStage('initial');
        setShowWordCheckModal(true);
    };
    const handleWordCheck = () => {
        setWordCheckStage('checking');
        setShowAnswerBlocks(true);
        //completion check is happening in LetterBlocks
    };
    const handleShowAnswer = () => {
        setWordCheckStage('answer');
        const correctLetters = state.currentWord.letters.map(letter => ({
            value: letter,
            isCorrect: true
        }));
        if (currentLetters.some(letter => !letter.isCorrect)) {
            setUsedShowAnswer(true); // check if user has correctly guessed the word or used showAnswer
        }
        setCurrentLetters(correctLetters);
        setIsCompleting(true);
    };
    const handleTryAgain = () => {
        handleWordRestart();
        setShowWordCheckModal(false);
        setShowAnswerBlocks(false);
    };
    const handleWordRestart = () => {
        dispatch({
            type: 'UPDATE_WORD_RETRIES',
            wordId: state.currentWord.id,
            retries: state.currentWord.retries - 1
        });
        dispatch({
            type: 'UPDATE_TIMER',
            seconds: CONSTANTS.TIMER[`LEVEL_${state.player.level}`]
        })
        // Reset the letters in LetterBlocks component
        setFocusedLetterIndex(0);
    };
    //Vārdu izaicinajuma pārmija
    const handleWordComplete = () => {
        // Wait for animations to complete before transitioning
        setTimeout(() => {
            setShowWordCheckModal(false);
            setIsVisible(false);
            setShowAnswerBlocks(false);
            setTimeout(() => {
                setRevealedTiles([]);
                if (usedShowAnswer === false) { // give bonus points only if player has actually guessed the word
                    dispatch({
                        type: 'UPDATE_PLAYER_SCORE',
                        // update with static win points for word challange depending on LEVEL
                        points: CONSTANTS.WIN_POINTS[`LEVEL_${state.player.level}`]
                    });
                } else {
                    // if not guessed by user takes penalty from word score in amount of default bonus for guessed word
                    dispatch({
                        type: 'UPDATE_WORD_SCORE',
                        points: CONSTANTS.LOOSE_POINTS[`LEVEL_${state.player.level}`]
                    })
                }
                dispatch({ type: 'COMPLETE_WORD' });
                setIsCompleting(false); // Reset the completing state
                setUsedShowAnswer(false) //return to false in case if it was true for the next word challange
            }, 300);
        }, 10); // Adjust this timing based on your animation duration
    };
    const handleGameReset = () => { //restart game, go back to title, close all tiles
        dispatch ( { type: 'RESET_GAME' } );
        setRevealedTiles([]);
    }
    const handleFocusChange = (index) => { //Change the focused block in letterBlocks
        setFocusedLetterIndex(index)
    }

    // Only show StartScreen on initial load
    if (!state.player.level && !state.player.words.length && !isLevelChange) {
        return <Index />
    }


    return (
        <div className="min-h-screen bg-[#00474e] text-white items-center justify-center pt-[1vh] md:pt-[6vh]">
            <div className="max-w-6xl mx-auto space-y-6">
                <div className="flex flex-col md:flex-row justify-center items-center gap-3 md:gap-[64px] xl:md:gap-[84px]">
                    {/*/*mobile buttons full block/*/}
                    <div className="flex-1 flex gap-0.5 md:hidden">
                        <LevelChangeButton level={state.player.level} handleLevelChange={handleLevelChange} />
                        <Timer onTimeout={handleTimeout} isPaused={isAnyModalOpen}/>
                        <ScoreDisplay score={state.player.score}/>
                    </div>

                    {/*desktop buttons left*/}
                    <div className="hidden md:flex md:w-1/6 md:flex-col gap-4 md:sticky">
                        <LevelChangeButton
                            level={state.player.level}
                            handleLevelChange={handleLevelChange}
                        />
                        <AboutButton
                            setModalType={setModalType}
                            setShowModal={setShowModal}
                            className="md:flex" //sets component to desktop view
                        />
                    </div>

                    {/*game board*/}
                    <div className="flex flex-col justify-center">
                        <div className="w-[90vw] h-[90vw] md:h-[60vh] md:w-[60vh]">
                            {state.currentWord && (
                                <>
                                    <div className={`
                                        flex flex-col gap-2 md:gap-5 xl:gap-7
                                        transition-all duration-300 ease-in 
                                        ${isVisible ? 'opacity-100' : 'opacity-0'}
                                    `}>
                                        <div className="
                                            flex flex-row
                                            items-center
                                            space-x-2
                                            justify-center
                                            md:mr-16
                                            md:order-last
                                            ">
                                            <div>
                                                <button
                                                    className="hidden md:block w-12 h-12 pl-5 ml-2"
                                                    onClick={() => {
                                                        setModalType('hint')
                                                        setShowModal(true)
                                                    }}
                                                ><img
                                                    className="
                                                        w-8
                                                        h-8"
                                                    src="/icons/help-circle.svg"
                                                    alt="Hint"
                                                />
                                                </button>
                                            </div>

                                            <div id="letterBlocks" className={showAnswerBlocks ? 'hidden' : ''}>
                                                <LetterBlocks
                                                    word={state.currentWord}
                                                    level={state.player.level}
                                                    onComplete={handleWordComplete} //word is completed and is correct
                                                    focusedIndex={focusedLetterIndex}
                                                    onFocusChange={handleFocusChange}
                                                    onWordFull={handleWordFull}
                                                />
                                            </div>
                                            {/* Answer Letter Blocks */}
                                            <div
                                                className={`${showAnswerBlocks ? '' : 'hidden'}`}
                                                key={wordCheckStage}
                                            >
                                                <div className="flex justify-center space-x-1 md:space-x-3">
                                                    {currentLetters.map((letter, index) => (
                                                        <motion.div
                                                            key={`${wordCheckStage}-${index}`}
                                                            className={`
                                                                w-10 h-10 md:h-[53px] md:w-[53px] xl:h-[71px] xl:w-[71px]
                                                                text-center 
                                                                text-[26.18px] md:text-[34.69px] xl:text-[46.47px]
                                                                tracking-[-0.93px] 
                                                                uppercase 
                                                                rounded-[2.91px] md:rounded 
                                                                font-bold font-outfit 
                                                                shadow-[-4px_4px_4px_0px_rgba(0,0,0,0.25)]
                                                            ${letter.isCorrect ? 'bg-[#00c475]' : 'bg-[#eb2227]'}
                                                        `}
                                                            animate={letter.isCorrect ? {} : {
                                                                backgroundColor: ['#eb2227', '#b72125'],
                                                            }}
                                                            style={{textShadow: '2px 4px 2px rgba(0, 0, 0, 0.25)'}}
                                                            transition={{duration: 1, repeat: Infinity}}
                                                        >
                                                            {letter.value}
                                                        </motion.div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-[0px_4px_8px_0px_rgba(0,0,0,0.25)] md:order-first">
                                            <TileGrid
                                                level={state.player.level}
                                                word={state.currentWord}
                                                onTileClick={handleTileClick}
                                                revealedTiles={revealedTiles}
                                                setRevealedTiles={setRevealedTiles}
                                                penalties={penalties}
                                                isCompleting={isCompleting}
                                            />
                                        </div>

                                        {/*Block for mobile media showing hint, progress, and about */}
                                        <div
                                            id={"mobileProgrssIndicator"}
                                            className="block md:hidden flex flex-row justify-between items-center p-3"
                                        >
                                            <button
                                                onClick={() => {
                                                    setModalType('hint');
                                                    setShowModal(true);
                                                }}
                                            >
                                                <img
                                                    src="/icons/help-circle.svg"
                                                    alt="Hint"
                                                    className=""
                                                />
                                            </button>
                                            <div
                                                className="
                                                    text-center
                                                    text-base
                                                    font-normal
                                                    font-outfit
                                                    text-[#00d881]"
                                            >
                                                {state.player.words.filter(w => w.completed).length} no {state.player.words.length}
                                            </div>
                                            <AboutButton
                                                setModalType={setModalType}
                                                setShowModal={setShowModal}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    {/*desktop right panel*/}
                    <div className="hidden md:flex md:w-1/6 md:flex-col gap-4 md:sticky">
                        <div><Timer onTimeout={handleTimeout} isPaused={isAnyModalOpen}/></div>
                        <div><ScoreDisplay score={state.player.score}/></div>
                    </div>
                </div>

                {/*footer with gallery*/}
                <div className="fixed bottom-0 left-0 right-0">
                    {/*progress*/}
                    <div className="hidden md:block text-center text-xl font-normal font-outfit text-[#00d881] pb-2">
                        {state.player.words.filter(w => w.completed).length} no {state.player.words.length}
                    </div>
                    <div className="hidden md:block mx-auto bg-[#082c2e] shadow-[0px_4px_8px_4px_rgba(0,0,0,0.25)]">
                        <Gallery words={state.player.words}/>
                    </div>
                    <div className="block md:hidden mx-auto bg-[#082c2e] shadow-[0px_4px_8px_4px_rgba(0,0,0,0.25)]">
                        <Gallery
                            words={state.player.words}
                            media={'mobile'}
                        />
                    </div>
                </div>
            </div>

            {showModal && (
                <Modal
                    type={modalType}
                    onClose={() => setShowModal(false)}
                    word={state.currentWord}
                    player={state.player}
                    restart={handleGameReset}
                    onShowAnswer={handleTimeoutShowAnswer}
                    onTryAgain={handleTimeoutTryAgain}
                />
            )}
            {showWordCheckModal && (
                <WordCheckModal
                    isOpen={showWordCheckModal}
                    word={state.currentWord}
                    onCheck={handleWordCheck}
                    onShowAnswer={handleShowAnswer}
                    onTryAgain={handleTryAgain}
                    onContinue={handleWordComplete}
                    stage={wordCheckStage}
                    isCorrect={currentLetters.every(letter => letter.isCorrect)}
                />
            )}
        </div>
    )
}
